import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
//import { GatsbyImage, getImage } from 'gatsby-plugin-image'
//import Layout from '../../components/layout'
import Container from '../../components/container';
import styled from 'styled-components';
import SEO from '../../components/seo';
//The KaTeX CSS file needs to be imported on a page to render the math correctly.
import "katex/dist/katex.min.css";

const PostWrapper = styled.div`
  section {
    flex-direction: column;
    width: 1020px;
    max-width: 100%;
    margin-bottom: 5rem;
  }
  .date{
    font-size:0.8em;
    margin-top: -1rem;
  }
  iframe {
    //display:center;
    height: 350px;
    width: 70%;
    @media (max-width: ${props => props.theme.sizes.mobile}) {
      width: 100%;
      }
  }
  .alignCenter{
    text-align:center;
    margin-bottom:2rem;
  }
  a{
    color:#9683EC;
    text-decoration: underline;
  }
  pre[class*="language-"] {
    background: black;
    padding: .5em;
    p, li, span {
      line-height: 2.5rem;
      font-size: 1.3rem !important;
    }
  }
  code[class*="language-"] {
    line-height: 2.5rem;
    font-size: 1.3rem !important;
  }
`;

export default function BlogPost({ data }) {
  //const image = getImage(data.mdx.frontmatter.hero_image)
  
  function display_preview_media(meta) {
    
    if (!meta.video_src_url){
      return (
        null
      )
    }
    
    return (
      <div className="alignCenter">
      <iframe
        src={meta.video_src_url}
        title={meta.video_title}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
      </div>
    )
  }

  const postSEO = data.mdx.frontmatter;

  return (
    <PostWrapper>
      <SEO title={postSEO.title}
      description={postSEO.preview}
      article={true}
      // image={postSEO.hero_image}
      keywords={postSEO.keywords}
      createdAt={postSEO.date}
      updatedAt={postSEO.updatedAt}
      />
      <Container>
      <h2>{data.mdx.frontmatter.title}</h2>
      <p className='date'>Posted: {data.mdx.frontmatter.date}</p>
      {display_preview_media(data.mdx.frontmatter)}
      <MDXRenderer>{data.mdx.body}</MDXRenderer>
      </Container>
    </PostWrapper>
  )
}

export const query = graphql`
  query($id: String) {
    mdx(id: {eq: $id}) {
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        updatedAt(formatString: "MMMM DD, YYYY")
        keywords
        preview
        video_src_url
        video_title
        hero_image_alt
        hero_image_credit_link
        hero_image_credit_text
        hero_image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`